import React from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import Aside from "./components/Aside";

import GeneralSettings from "./components/settings/GeneralSettings";
import BrandingSettings from "./components/settings/BrandingSettings";
import PaymentLinkStyle from "./components/settings/PaymentLinkStyle";
import BankAccountSettings from "./components/settings/BankAccountSettings";
import CustomDataCaptureSettings from "./components/settings/CustomDataCaptureSettings";
import CustomerEmailsSettings from "./components/settings/CustomerEmailsSettings";
import NotificationsSettings from "./components/settings/NotificationsSettings";
import PasswordSettings from "./components/settings/PasswordSettings";
import AdvancedSettings from "./components/settings/AdvancedSettings";
import ExportSettings from "./components/settings/ExportSettings";
import StripeStandardConnectSettings from "./components/settings/StripeStandardConnectSettings";
import ShopSettings from "./components/settings/ShopSettings";
import ApiSettings from "./components/settings/ApiSettings";
import TokenSettings from "./components/settings/TokenSettings";

import SuccessModal from "./components/modal/SuccessModal";
import ConnectForm from "./components/kyc/ConnectForm";

import MultiFactorAuthentication from "./components/multi-factor-authentication/MultiFactorAuthentication";
import CashDiscount from "./CashDiscount";

import Event from "../utils/Event";
import Backend from "../utils/Backend";
import AuthManager from "../utils/AuthManager";
import Notify from "../utils/Notify";
import General from "../utils/General";
import CompanyProcessor from "../utils/CompanyProcessor";
import Permissions from "../utils/Permissions";
import PaymentMethodUtils from "../utils/PaymentMethods";

const TAB_GENERAL = {
  title: "General",
  subtitle: "Control your basic default settings",
  icon: "person",
  url: "/settings/general",
};

const TAB_BRANDING = {
  title: "Branding",
  subtitle: "Add your own corporate identity",
  icon: "cross_hair",
  url: "/settings/branding",
};

const TAB_PAYMENT_LINK_STYLE = {
  title: General.toTitleCase(window.General.Branding.LinkName)+' Style',
  subtitle: "Choose your "+General.toTitleCase(window.General.Branding.LinkName)+' style',
  icon: "layout_style",
  url: "/settings/payment-link-style",
};

const TAB_BANK_ACCOUNT = {
  title: "Verification - Stripe",
  subtitle: "Connect your bank account",
  icon: "card",
  url: "/settings/bank-account",
};

let TAB_CUSTOM_DATA = {
  title: "Custom Data Capture",
  subtitle: `Add custom input fields to your ${window.General.Branding.Name}`,
  icon: "folder",
  url: "/settings/custom-data-capture",
};

const TAB_CUSTOMER_EMAIL = {
  title: "Payment Emails",
  subtitle: "Adjust your 'Thank you' email",
  icon: "mail",
  url: "/settings/emails",
};

const TAB_NOTIFICATION = {
  title: "Payment Notifications",
  subtitle: "Control when you will be notified",
  icon: "bell",
  url: "/settings/notifications",
};

const TAB_KYC = {
  title: "Account Verification",
  subtitle: "Tell us about your business",
  icon: "person",
  url: "/settings/kyc",
};

const TAB_PASSWORD = {
  title: "Change Password",
  subtitle: "Keep your password safe",
  icon: "shield",
  url: "/settings/password",
};

const TAB_ADVANCED_SETTINGS = {
  title: "Receipts and Redirects",
  subtitle: "Tailor your customers experience",
  icon: "pencil",
  url: "/settings/advanced",
};

const TAB_EXPORT = {
  title: "Export",
  subtitle: "Export payment information",
  icon: "export",
  url: "/settings/export",
};

const TAB_API = {
  title: "Api",
  subtitle: "Automate your operations",
  icon: "cpu",
  url: "/settings/api",
};

const TAB_STRIPE_STANDARD = {
  title: "Verification - Stripe",
  subtitle: "Manage your bank details",
  icon: "card",
  url: "/settings/stripe",
};

const TAB_TOKEN = {
  title: "Verification - Token",
  subtitle: "Manage your bank details",
  icon: "bank",
  url: "/settings/token",
};

const TAB_SHOP = {
  title: "Shop",
  subtitle: "Manage your shop",
  icon: "cart",
  url: "/settings/shop",
};

const TAB_PAYMENT_ATTEMPTS = {
  title: "Payment Attempts",
  subtitle: "Manage all your payments",
  icon: "paymentAttempts",
  url: "/settings/payment-attempts",
};

const TAB_BILLING = {
  title: "Billing",
  subtitle: "Manage all your invoices",
  icon: "file",
  url: "/billing",
};

const TAB_MULTI_FACTOR_AUTHENTICATION = {
  title: "Multi Factor Authentication",
  subtitle: "Manage Multi Factor Authentication",
  icon: "multiFactorAuthentication",
  component: MultiFactorAuthentication,
  url: "/settings/multi-factor-authentication",
};

const TAB_CASH_DISCOUNT = {
  title: "Cash Discount",
  subtitle: "Enable cash discount",
  icon: "cashDiscount",
  component: CashDiscount,
  url: "/settings/cash-discount",
};

const TABS = [
  TAB_GENERAL,
  TAB_BRANDING,
  TAB_PAYMENT_LINK_STYLE,
  TAB_CUSTOM_DATA,
  TAB_SHOP,
  TAB_PAYMENT_ATTEMPTS,
  TAB_ADVANCED_SETTINGS,
  TAB_CUSTOMER_EMAIL,
  TAB_NOTIFICATION,
  TAB_PASSWORD,
  TAB_MULTI_FACTOR_AUTHENTICATION,
  TAB_BILLING,
];

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    TABS[2].title = General.toTitleCase(window.General.Branding.LinkName)+' Style'
    TABS[2].subtitle = "Choose your "+General.toTitleCase(window.General.Branding.LinkName)+' style'

    TABS[3] = {
      title: "Custom Data Capture",
      subtitle: `Add custom input fields to your ${window.General.Branding.LinkName}`,
      icon: "folder",
      url: "/settings/custom-data-capture",
    };

    let company = AuthManager.currentUser.company;
    let tabs = TABS;


    let stripeStandardConnected = false
    let stripeCustomConnected = false
    let tokenConnected = false
    let tsysConnected = false

    let stripeStandardData = null;
    let stripeCustomData = null;

    company.company_processors.forEach((companyProcessor, i) => {
      let processorSlug = companyProcessor.processor_slug
      if(processorSlug.indexOf("token") > -1){
        tokenConnected = true
      }
      else if(processorSlug.indexOf("stripe_standard") > -1){
        stripeStandardConnected = true
        stripeStandardData = {
          stripeProcessorSlug: processorSlug
        }
      }
      else if(processorSlug.indexOf("stripe") > -1){
        stripeCustomConnected = true
      }
      else if(processorSlug.indexOf("tsys") > -1){
        tsysConnected = true
      }
    });

    Object.keys(company.payment_methods).forEach((paymentMethodType, i) => {
      let paymentMethod = company.payment_methods[paymentMethodType]
      if(!paymentMethod){
        return
      }
      let { processor, data } = paymentMethod
      if(processor === "stripe" || processor === "stripe_test"){
        stripeCustomData = {
          stripePublicKey: data.platform_public_key,
          stripeAccountId: data.account_id,
          stripeProcessorId: paymentMethod.processor_id,
          stripeProcessorSlug: processor
        }
      }
    });

    if(stripeCustomConnected){
      if (tabs.find((tab) => tab.url === TAB_BANK_ACCOUNT.url) == null) {
        tabs.push(TAB_BANK_ACCOUNT);
        tabs.push(TAB_KYC);
      }
    }

    if(stripeStandardConnected){
      if (tabs.find((tab) => tab.url === TAB_STRIPE_STANDARD.url) == null) {
        tabs.push(TAB_STRIPE_STANDARD);
      }
    }

    if(tokenConnected){
      if (tabs.find((tab) => tab.url === TAB_TOKEN.url) == null) {
        tabs.push(TAB_TOKEN)
      }
    }

    if(tsysConnected){
      if (tabs.find((tab) => tab.title === TAB_CASH_DISCOUNT.title) == null) {
        tabs.push(TAB_CASH_DISCOUNT);
      }
    }

    if (
      tabs.find((tab) => tab.title === TAB_EXPORT.title) == null &&
      Permissions.hasCreatePermission(Permissions.EXPORT)
    ) {
      tabs = [...tabs, TAB_EXPORT, TAB_API];
    }

    this.state = {
      tabs,
      company,
      tsysConnected,
      tokenConnected,
      stripeCustomData,
      stripeCustomConnected,
      stripeStandardData,
      stripeStandardConnected,
      activeTabIndex: this._getActiveTabIndex(tabs),
      loading: false,
      companyMember: AuthManager.currentUser,
      phone: AuthManager.currentUser.user.phone_country_code
        ? `${AuthManager.currentUser.user.phone_country_code}${AuthManager.currentUser.user.phone_number}`
        : "+353",
    };
  }

  componentDidMount() {
    Event.on("ADD_ON_ALERT", (url) => {
      if (!url) {
        return;
      }
      this.setState({
        activeTabIndex: 0,
      });
    });
  }

  _showSuccessAlert(title, message) {
    this.setState({ showSuccess: true, title, message });
    setTimeout(() => {
      this.setState({ showSuccess: false, title: null, message: null });
    }, 2000);
  }

  _validateCompanyMember() {
    let { companyMember } = this.state;

    let { current_password, password, verifyPassword } = companyMember.user;

    let isValid = true;
    let error = null;

    if (!current_password || current_password === "") {
      isValid = false;
      error = "Please enter your current password";
    }
    if (!password || password === "") {
      isValid = false;
      error = "Please enter a valid password";
    } else if (password !== verifyPassword) {
      isValid = false;
      error = "Passwords don't match";
    }

    if (error) {
      Notify.error(error);
    }

    return isValid;
  }

  _handleUpdateCompany() {
    let { company } = this.state;

    this.setState({ loading: true });
    Backend.updateCompany(company)
      .then((company) => {
        AuthManager.currentUser.company = company;
        Notify.success("Your details have been updated successfully");
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        Notify.error(error.message);
      });
  }

  _handleUpdateCompanyMember(password = false) {
    let { companyMember } = this.state;

    let message = "Your details have been updated successfully";

    if (password) {
      if (!this._validateCompanyMember()) {
        return;
      }
      message = "Your password has been changed successfully!";
    }

    this.setState({ loading: true });
    Backend.updateAccount(companyMember)
      .then((companyMember) => {
        if (password) {
          companyMember.user.password = "";
          companyMember.user.verifyPassword = "";
          companyMember.user.current_password = "";
        }
        this.setState({
          loading: false,
          companyMember,
        });
        Notify.success(message);
      })
      .catch((error) => {
        Notify.error(error.message.toString());
        this.setState({
          loading: false,
        });
      });
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

  render() {
    let {
      tabs,
      activeTabIndex,
      tsysConnected,
      tokenConnected,
      stripeCustomData,
      stripeCustomConnected,
      stripeStandardData,
      stripeStandardConnected
    } = this.state;

    return (
      <div className={"card card-custom page settings"}>
        <div className={"card-body p-0"}>
          <div
            className={"wizard wizard-2"}
            id={"kt_wizard_v2"}
            data-wizard-state={"first"}
            data-wizard-clickable={"false"}
          >
            <div
              className={"wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"}
            >
              <Aside
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onClick={(tab, index) => {
                  if (tab.title === "Payment Attempts") {
                    this.props.history.push(tab.url);
                  }

                  this.setState(
                    {
                      activeTabIndex: index,
                    },
                    () => {
                      this.props.history.push(tab.url);
                    }
                  );
                }}
              />
            </div>
            <div className={"wizard-body py-8 px-8 py-lg-20 px-lg-10"}>
              <div className={"row"}>
                <Switch>
                  <Route
                    exact
                    path="/settings/general"
                    render={(props) => {
                      return (
                        <GeneralSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/branding"
                    render={(props) => {
                      return (
                        <BrandingSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/payment-link-style"
                    render={(props) => {
                      return (
                        <PaymentLinkStyle
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/shop"
                    render={(props) => {
                      return (
                        <ShopSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/advanced"
                    render={(props) => {
                      return (
                        <AdvancedSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/custom-data-capture"
                    render={(props) => {
                      return (
                        <CustomDataCaptureSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/emails"
                    render={(props) => {
                      return (
                        <CustomerEmailsSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/notifications"
                    render={(props) => {
                      return (
                        <NotificationsSettings
                          companyMember={this.state.companyMember}
                          phone={this.state.phone}
                          loading={this.state.loading}
                          onUpdated={(companyMember) =>
                            this.setState({ companyMember })
                          }
                          onSave={() => this._handleUpdateCompanyMember()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/password"
                    render={(props) => {
                      return (
                        <PasswordSettings
                          companyMember={this.state.companyMember}
                          loading={this.state.loading}
                          onUpdated={(companyMember) =>
                            this.setState({ companyMember })
                          }
                          onSave={() => this._handleUpdateCompanyMember(true)}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/export"
                    render={(props) => {
                      return (
                        <ExportSettings
                          loading={this.state.loading}
                          onExported={() => {
                            this._showSuccessAlert(
                              "Export Request Sent",
                              "Your export will be sent to you by email shortly"
                            );
                          }}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/api"
                    render={(props) => {
                      return <ApiSettings loading={this.state.loading} />;
                    }}
                  />

                  <Route
                    exact
                    path="/settings/multi-factor-authentication"
                    render={(props) => {
                      return <MultiFactorAuthentication />;
                    }}
                  />

                  <Route
                    exact
                    path="/settings/cash-discount"
                    render={(props) => {
                      if(!tsysConnected){
                        return null
                      }
                      return (
                        <CashDiscount
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/stripe"
                    render={(props) => {
                      if(stripeStandardConnected && stripeStandardData){
                        return null
                      }
                      return (
                        <StripeStandardConnectSettings
                          company={this.state.company}
                          loading={this.state.loading}
                          processorSlug={stripeStandardData.stripeProcessorSlug}
                        />
                      );
                    }}
                  />


                  <Route
                    exact
                    path="/settings/bank-account"
                    render={(props) => {
                      if(stripeCustomConnected && stripeCustomData){
                        return null
                      }

                      return (
                        <BankAccountSettings
                          {...stripeCustomData}
                          company={this.state.company}
                          loading={this.state.loading}
                          onUpdated={(company) => this.setState({ company })}
                          onSave={() => this._handleUpdateCompany()}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/kyc"
                    render={(props) => {
                      if(stripeCustomConnected && stripeCustomData){
                        return null
                      }
                      return (
                        <div className="col">
                          <form className="form fv-plugins-bootstrap fv-plugins-framework">
                            <div
                              className="pb-5"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              <ConnectForm
                                slug={stripeCustomData.stripeProcessorSlug}
                              />
                            </div>
                          </form>
                        </div>
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/settings/token"
                    render={(props) => {
                      if(!tokenConnected){
                        return null
                      }

                      return (
                        <TokenSettings
                          company={this.state.company}
                          loading={this.state.loading}
                        />
                      );
                    }}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <SuccessModal
          show={this.state.showSuccess}
          title={this.state.title}
          message={this.state.message}
          onHide={() => this.setState({ showSuccess: false })}
        />
      </div>
    );
  }
}
